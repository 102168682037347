import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

import { socials } from "../assets/data/socials";
const light = "#d7d7d7";
const lightDarken = "#c1c1c1";
const dark = "#424242";
const darkLighten = "#484848";
const red = "#e74c3c";
const green = "#0f9b0f";

const CurrentYear = () => {
	const date = new Date();
	const year = date.getFullYear();

	return <p id="copyright">Mohammad Kebbi | Copyright &copy; {year}</p>;
};

const Social = ({ url, icon }) => {
	return (
		<span>
			<a href={url} target="_blank" rel="noopener noreferrer">
				<span className="secondary-color">
					<FontAwesomeIcon icon={icon} />
				</span>
			</a>{" "}
		</span>
	);
};

const Footer = () => {
	const [darkTheme, setDarkTheme] = useState(false);
	useEffect(() => {
		setDarkTheme(JSON.parse(window.sessionStorage.getItem("darkTheme")));
	}, []);
	// React useEffect hook that will fire up
	// when "darkTheme" changes
	useEffect(() => {
		window.sessionStorage.setItem("darkTheme", darkTheme);
		// Accessing scss variable "--background-color"
		// and "--text-color" using plain JavaScript
		// and changing the same according to the state of "darkTheme"
		const root = document.documentElement;
		const nav = document.getElementById("nav");
		nav.classList.add(darkTheme ? "nav-bg-dark" : "nav-bg-light");
		nav.classList.remove(darkTheme ? "nav-bg-light" : "nav-bg-dark");
		root?.style.setProperty("--primary", darkTheme ? dark : light);
		root?.style.setProperty(
			"--deep-primary",
			darkTheme ? darkLighten : lightDarken
		);
		root?.style.setProperty("--invert-primary", darkTheme ? light : dark);
		root?.style.setProperty("--secondary", darkTheme ? green : red);
	}, [darkTheme]);
	return (
		<div className="footer">
			<div className="socials">
				{socials.map((s, index) => {
					return <Social key={s.id} {...s}></Social>;
				})}{" "}
				<a onClick={() => setDarkTheme(!darkTheme)}>
					<span className="secondary-color">
						<FontAwesomeIcon icon={faLightbulb} />
					</span>
				</a>
			</div>
			<CurrentYear />
		</div>
	);
};

export default Footer;
