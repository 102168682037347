import React from "react";
import { Link } from "react-router-dom";
const Error = () => {
	return (
		<div className="page">
			<div className="container">
				<h1>Page not found</h1>
				<div className="button-container">
					<Link to="/" className="btn btn-1">
						Go back
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Error;
