import {
	faFacebookSquare,
	faGithubSquare,
	faInstagramSquare,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export const socials = [
	{
		id: 1,
		url: "https://github.com/TheBroMoe",
		icon: faGithubSquare,
	},
	{
		id: 2,
		url: "https://linkedin.com/in/moe-kebbi",
		icon: faLinkedin,
	}
];
