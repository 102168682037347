import React from "react";
import { Link } from "react-router-dom";
import { profile } from "../assets/data/profile";
import { about } from "../assets/data/about";

import mainPortrait from "../assets/images/grad_photo.png";

const SideProfile = ({
	residence,
	industry,
	employment,
	education,
	university,
	experience,
}) => {
	return (
		<section className="side-profile">
			<img src={mainPortrait} alt="Main Portrait" className="side-pic" />
			<h2 id="side-profile-header">Mohammad Kebbi</h2>

			<h3>Current Residence</h3>
			<p>{residence}</p>
			<h3>Years of Experience</h3>
			<h3>{experience}</h3>
			<h3>Currently Work in</h3>
			<p>{industry}</p>
			<h3>Current Employment</h3>
			<p>{employment}</p>
			<h3>University</h3>
			<p>{university}</p>
			<h3>Education</h3>
			<p>{education}</p>
		</section>
	);
};

const AboutContainer = ({
	opener,
	description,
	summary,
	highlights,
	interests,
	hobbies,
	volunteer,
	fun,
}) => {
	return (
		<div>
			<SideProfile {...profile} />

			<div id="about-container" className="container">
				<h1>
					About <span className="secondary-color">Me</span>
				</h1>
				<p>{opener}</p>
				<p>{summary}</p>
				<p>{description}</p>
				<h2>
					I'm <span className="secondary-color">Good At</span>
				</h2>
				<p>{highlights}</p>
				<h2>
					I'm <span className="secondary-color">Interested In</span>
				</h2>
				<p>{interests}</p>
				<h2>
					Favorite <span className="secondary-color">Hobbies</span>
				</h2>
				<p>{hobbies}</p>
				<h2>
					Volunteer <span className="secondary-color">Experience</span>
				</h2>
				<p>{volunteer}</p>
				<h2>
					Fun <span className="secondary-color">Fact</span>
				</h2>
				<p>{fun}</p>
				<div className="button-container" id="portfolio-btn">
					<Link to="/summary" className="btn btn-1">
						View Portfolio
					</Link>
				</div>
			</div>
		</div>
	);
};

const About = () => {
	return (
		<div className="page" id="about-page">
			<AboutContainer {...about} />
		</div>
	);
};

export default About;
