export const certs = [
	{
		id: 0,
		img: "https://training.linuxfoundation.org/wp-content/uploads/2020/07/kubernetes-security-specialist-logo-300x285.png",
		title: "Certified Security Specialist",
		status: "Awarded",
		date: "December 2022",
		url: "https://www.credly.com/badges/c5599744-eb4a-4aaf-8abc-4bf76ced14b5/public_url",
	},
	{
		id: 1,
		img: "https://training.linuxfoundation.org/wp-content/uploads/2019/03/logo_cka_whitetext-300x293.png",
		title: "Certified Kubernetes Administrator",
		status: "Awarded",
		date: "September 2022",
		url: "https://www.credly.com/badges/9b2eea91-1cc3-464d-b545-63874e346a05/public_url",
	},
	{
		id: 2,
		img: "https://training.linuxfoundation.org/wp-content/uploads/2019/03/kubernetes-ckad-color-300x294.png",
		title: "Certified Kubernetes Application Developer",
		status: "Awarded",
		date: "September 2022",
		url: "https://www.credly.com/badges/913c5bd0-7687-483a-8494-01f65bf5f7f9/public_url",
	},
	{
		id: 3,
		img: "https://images.credly.com/size/340x340/images/f0adcc07-4388-459f-9b98-9a487ff1e8fd/cert_mark_SP_badge_large_300px.png",
		title: "Certified SAFe® 5 Practitioner",
		status: "Awarded",
		date: "September 2022",
		url: "https://www.credly.com/badges/67b48a6d-8a33-4586-aaba-0f7d436bc7c4/public_url",
	},
	{
		id: 4,
		img: "https://enroll.isc2.org/images/DevSecOps-Badge.png",
		title: "(ISC)² DevSecOps: Integrating Security into DevOps",
		status: "Awarded",
		date: "December 2021",
		url: "#certification",
	},
	{
		id: 5,
		img: "https://miro.medium.com/max/750/0*2AljM5mM2m8xM-88.png",
		title: "Google Cloud Professional Cloud Security Engineer",
		status: "Awarded",
		date: "November 2021",
		url: "https://www.credential.net/1ab81125-440b-4fe6-a9f4-2610b986c53a?key=72b5b9a96b1afef48506d62b0ebe8c4dcd1ff7e5094087a9533cfb90975d020b",
	},
	{
		id: 6,
		img: "https://www.datocms-assets.com/2885/1586800192-terraformassociateweb.png?fit=max&fm=webp&q=80&w=2000",
		title: "HashiCorp Certified: Terraform Associate",
		status: "Awarded",
		date: "September 2021",
		url: "https://www.credly.com/badges/00b46b68-bfec-4669-96b7-9866032d521a/linked_in",
	},
	// {
	// 	id: 6,
	// 	img: "https://miro.medium.com/max/300/1*TvnzxcGuSS86skreK8M_fg.png",
	// 	title: "Google Cloud Professional Cloud Architect",
	// 	status: "Tentative",
	// 	date: "August 2022",
	// 	url: "https://cloud.google.com/certification/cloud-architect",
	// },
];
