import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
	return (
		<div>
			<ul id="nav" className="nav nav-bg-light">
				<li className="nav-item slam-left">
					<Link to="/">
						<strong>
							Mohammad <span className="secondary-color">Kebbi</span>
						</strong>
					</Link>
				</li>
				<li className="nav-item">
					<Link to="/about">
						About{" "}
						<span className="secondary-color">
							<FontAwesomeIcon icon={faUser} />
						</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link to="/summary">
						Summary{" "}
						<span className="secondary-color">
							<FontAwesomeIcon icon={faCode} />
						</span>
					</Link>
				</li>
				<li className="nav-item">
					<a
						className="contact"
						href="https://mail.google.com/mail/?view=cm&fs=1&to=kebbi@ualberta.ca"
						target="_blank"
						rel="noopener noreferrer"
					>
						Contact{" "}
						<span className="secondary-color">
							<FontAwesomeIcon icon={faEnvelope} />
						</span>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Navbar;
