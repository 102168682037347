export const about = {
	opener: "My name is Mohammad (I also go by Moe). ",
	summary:
		"I an Experienced DevOps, Security, Site Reliability, and Platform Engineer with 5 years designing, implementing, and managing resilient IT infrastructures. Proficient in streamlining development, fortifying security, and orchestrating cloud solutions for efficiency. Collaborative leader achieving business objectives through cross-functional teamwork.",
	description:
		"With a strong background in computer networking and system administration, full-stack and site reliability engineering, and robotics and electronics design, I have extensive experience in utilizing configuration management, CI/CD, Serverless, and DevSecOps processes across GCP, AWS, and Azure environments. Having contributed to open-source software platforms and worked on multiple large-scale, multi-disciplinary projects, I am passionate about leveraging my expertise in software and hardware to enhance the security and scalability of mission-critical systems for enterprise-level software.",
	highlights:
		"Programming, Containerization, DevOps, Database Management, APIs, Virtualization, Web services, Security and Recovery, Networking, Mechatronics, and Eating.",
	interests:
		"Cloud Security, Game Development, Blockchain, IoT, Machine Learning, Robotics, and Business Administration!",
	hobbies:
		"Rock climbing, running, playing video games, watching old movies, or drinking tea!",
	volunteer:
		"I currently volunteer as a peer support counselor and mentor for students in STEM.",
	fun: "In my free time, I love reading about psychology, and philosophy.",
};
