export const skills = [
	{
		skill: "",
		groups: [
			"all",
			"backend",
			"certified",
			"CI/CD",
			"cloud",
			"currently working in",
			"database",
			"electronics",
			"frameworks",
			"frontend",
			"gateway",
			"hardware",
			"IAM",
			"infrastructure",
			"languages",
			"mobile",
			"monitoring",
			"networking",
			"OS",
			"service mesh",
			"scripting",
			"security",
			"tools",
		],
	},
	{
		skill: "GCP",
		groups: ["certified", "infrastructure", "cloud"],
	},
	{ skill: "VHDL", groups: ["languages", "hardware"] },
	{ skill: "Verilog", groups: ["languages", "hardware"] },
	{
		skill: "Kubernetes",
		groups: ["infrastructure", "currently working in", "cloud", "certified"],
	},
	{
		skill: "Helm",
		groups: ["infrastructure", "currently working in", "cloud", "certified"],
	},
	{
		skill: "Kustomize",
		groups: ["infrastructure", "currently working in", "cloud", "certified"],
	},
	{
		skill: "Docker",
		groups: ["infrastructure", "currently working in", "cloud"],
	},
	{ skill: "Git", groups: ["infrastructure", "currently working in"] },
	{
		skill: "Github Actions",
		groups: ["infrastructure", "currently working in", "CI/CD", "cloud"],
	},
	{
		skill: "Gitlab",
		groups: ["CI/CD", "cloud"],
	},
	{
		skill: "AWS",
		groups: ["infrastructure", "currently working in", "cloud"],
	},
	{ skill: "Azure", groups: ["infrastructure", "cloud"] },
	{
		skill: "Ansible",
		groups: ["infrastructure", "currently working in", "cloud"],
	},
	{ skill: "Chef", groups: ["infrastructure", "cloud"] },
	{ skill: "Puppet", groups: ["infrastructure", "cloud"] },
	{
		skill: "OpenShift",
		groups: ["infrastructure", "cloud"],
	},
	{
		skill: "Jenkins",
		groups: ["infrastructure", "currently working in", "CI/CD"],
	},
	{ skill: "TravisCI", groups: ["infrastructure", "CI/CD"] },
	{ skill: "C", groups: ["languages"] },
	{ skill: "C++", groups: ["languages"] },
	{ skill: "C#", groups: ["languages"] },
	{ skill: "Python", groups: ["languages", "scripting", "currently working in"] },
	{ skill: "Java", groups: ["languages"] },
	{ skill: "Go", groups: ["languages", "currently working in"] },
	{ skill: "MATLAB", groups: ["languages"] },
	{ skill: "Visual Basic", groups: ["languages", "scripting"] },
	{
		skill: "Powershell",
		groups: ["scripting", "languages"],
	},
	{ skill: "Fusion 360", groups: ["tools", "hardware", "electronics"] },
	{ skill: "Signal Processing", groups: ["electronics"] },
	{ skill: "SSO", groups: ["security", "IAM"] },
	{
		skill: "Policy Creation",
		groups: ["security", "IAM"],
	},
	{
		skill: "Secret Management",
		groups: ["security", "IAM"],
	},
	{
		skill: "Cloud Identity",
		groups: ["security", "IAM", "cloud"],
	},
	{
		skill: "Cloud Architecture",
		groups: ["infrastructure", "currently working in", "cloud"],
	},
	{ skill: "Encryption", groups: ["security"] },
	{ skill: "X.509 Certificate Management", groups: ["security", "networking"] },
	{ skill: "Tokenization", groups: ["security", "currently working in"] },
	{ skill: "Pen Testing", groups: ["security"] },
	{
		skill: "Active Directory",
		groups: ["security", "IAM"],
	},
	{ skill: "LDAP", groups: ["security", "IAM"] },
	{
		skill: "Vulnerability Assessments",
		groups: ["security", "currently working in"],
	},
	{
		skill: "Firewalls",
		groups: ["networking", "security"],
	},
	{ skill: "Load Balancing", groups: ["networking", "currently working in"] },
	{ skill: "VegaDNS", groups: ["networking", "currently working in"] },
	{ skill: "Proxies", groups: ["networking"] },
	{ skill: "Subnetting", groups: ["networking"] },
	{
		skill: "SSL",
		groups: ["networking", "security"],
	},
	{ skill: "CDNs", groups: ["networking"] },
	{ skill: "EagleCAD", groups: ["tools", "electronics"] },
	{ skill: "LTSpice", groups: ["tools", "electronics"] },
	{ skill: "Cadence", groups: ["tools", "electronics"] },
	{ skill: "Bash", groups: ["scripting", "currently working in"] },
	{ skill: "GNU", groups: ["scripting", "currently working in"] },
	{ skill: "Batch Scripting", groups: ["scripting"] },
	{
		skill: "Terraform",
		groups: [
			"certified",
			"currently working in",
			"infrastructure",
			"languages",
			"cloud",
		],
	},
	{
		skill: "Terragrunt",
		groups: ["currently working in", "infrastructure", "cloud"],
	},
	{
		skill: "Argo CD",
		groups: ["infrastructure", "currently working in", "CI/CD"],
	},
	{
		skill: "Harness",
		groups: ["infrastructure", "CI/CD"],
	},
	{
		skill: "Datadog",
		groups: ["infrastructure", "monitoring", "currently working in"],
	},
	{
		skill: "New Relic",
		groups: ["infrastructure", "monitoring"],
	},
	{
		skill: "Dynatrace",
		groups: ["infrastructure", "monitoring"],
	},
	{
		skill: "Prometheus",
		groups: ["infrastructure", "currently working in", "monitoring"],
	},
	{
		skill: "Splunk",
		groups: ["infrastructure", "monitoring"],
	},
	{
		skill: "Elasticsearch",
		groups: ["infrastructure", "monitoring"],
	},
	{
		skill: "Grafana",
		groups: ["infrastructure", "monitoring"],
	},
	{
		skill: "VMware",
		groups: ["infrastructure", "cloud"],
	},
	{ skill: "Node", groups: ["frameworks", "backend"] },
	{ skill: "Nginx", groups: ["backend", "gateway"] },
	{ skill: "Kong", groups: ["backend", "gateway"] },
	{ skill: "Anthos", groups: ["backend", "gateway", "service mesh", "cloud"] },
	{ skill: "Istio", groups: ["backend", "gateway", "service mesh", "cloud"] },
	{ skill: "Consul", groups: ["backend", "gateway", "service mesh", "cloud", "currently working in"] },
	{ skill: "Arduino", groups: ["hardware"] },
	{ skill: "Raspberry Pi", groups: ["hardware"] },
	{ skill: "Express", groups: ["frameworks", "backend"] },
	{ skill: "Flask", groups: ["frameworks", "backend"] },
	{ skill: "Django", groups: ["frameworks", "backend"] },
	{ skill: "Flutter", groups: ["frameworks", "mobile"] },
	{ skill: "Android SDK", groups: ["tools", "mobile"] },
	{ skill: "React Native", groups: ["frameworks", "mobile"] },
	{ skill: "Javascript", groups: ["frontend", "backend", "languages"] },
	{ skill: "Typescript", groups: ["frontend", "backend", "languages"] },
	{ skill: "ROS", groups: ["frameworks"] },
	{ skill: "LaTeX", groups: ["tools"] },
	{ skill: "Windows", groups: ["OS"] },
	{
		skill: "Windows Server",
		groups: ["OS", "infrastructure"],
	},
	{ skill: "Unix", groups: ["OS", "currently working in"] },
	{ skill: "Kali Linux", groups: ["OS"] },
	{ skill: "MySQL", groups: ["database"] },
	{ skill: "PSQL", groups: ["database"] },
	{ skill: "Oracle", groups: ["database"] },
	{ skill: "MongoDB", groups: ["database"] },
	{ skill: "Redis", groups: ["database"] },
	{ skill: "Cassandra", groups: ["database"] },
	{ skill: "Firebase", groups: ["database"] },
	{ skill: "Excel", groups: ["tools", "scripting"] },
	{
		skill: "Vault",
		groups: ["security", "tools", "cloud"],
	},
	{
		skill: "Vagrant",
		groups: ["tools", "cloud", "currently working in"],
	},
	{ skill: "MacOS", groups: ["OS", "currently working in"] },
	{ skill: "HTML5", groups: ["frontend", "languages"] },
	{ skill: "CSS", groups: ["frontend", "languages"] },
	{ skill: "React", groups: ["frontend", "frameworks"] },
	{ skill: "NextJS", groups: ["frontend", "backend", "frameworks"] },
	{ skill: "Wordpress", groups: ["backend", "frontend", "frameworks"] },
].sort((a, b) => (a.skill > b.skill ? 1 : -1));
