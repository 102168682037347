import React from "react";
import mainPortrait from "../assets/images/main-portrait.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
const Home = () => {
	return (
		<div className="page">
			<div id="main-container" className="container">
				<h1>
					Mohammad <span className="secondary-color">Kebbi</span>
				</h1>
				<img
					src={mainPortrait}
					alt="Main Portrait"
					className="responsive profile-pic"
				/>
				<div className="main-icons">
					<a
						href="https://github.com/TheBroMoe"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FontAwesomeIcon icon={faGithubSquare} />
					</a>{" "}
					<a
						href="https://linkedin.com/in/moe-kebbi"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a>
				</div>
				<h1>
					Software <span className="secondary-color">Engineer</span>
				</h1>
				<div className="button-container">
					<Link to="/about" className="btn btn-2">
						Start Here
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Home;
