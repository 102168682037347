import React from "react";
// react router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// Headers
import Header from "../Main/Header";
import Footer from "../Main/Footer";

// pages
import Home from "./Home";
import About from "./About";
import Summary from "./Summary";
import Error from "./Error";

const RouterSetup = () => {
	return (
		<Router>
			<Header />
			<Route
				render={({ location }) => (
					<TransitionGroup>
						<CSSTransition key={location.key} timeout={500} classNames="fade">
							<Switch location={location}>
								<Route exact path="/">
									<Home />
								</Route>
								<Route path="/summary">
									<Summary />
								</Route>
								<Route path="/about">
									<About />
								</Route>
								<Route path="*">
									<Error />
								</Route>
							</Switch>
						</CSSTransition>
					</TransitionGroup>
				)}
			/>
			<Footer />
		</Router>
	);
};

export default RouterSetup;
