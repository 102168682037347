import cisco from "../images/cisco_logo.png";
import telus from "../images/telus_logo.png";
import arvp from "../images/ARVP_Logo.png";
import coe from "../images/coe_logo.png";

export const jobs = [
	{
		id: 0,
		logo: cisco,
		url: "https://www.cisco.com",
		alt: "Cisco",
		date: "October 2023 - Present",
		roles: [
			{
				title: "Software Engineer - Cloud Infrastructure Engineering",
				summary:
					"Part of the Service Discovery team, supporting the Cisco Security Cloud: a global, cloud-delivered, integrated platform that secures and connects organizations of any shape and size (840,000 networks, 67 million mailboxes, and 87 million endpoints - and counting!).",
				qualifications: [
					"Developed a Cisco Webex Chatbot with an embedding model that enhances team operations by automating deployment requests, generating infrastructure configurations, providing documentation direction, and managing issue tracking.",
					"Implemented Terragrunt in Jenkins CI/CD, optimizing the team's infrastructure deployment workflow for increased efficiency and reliability.",
					"Enhanced performance testing for Consul servers and clients, contributing valuable insights and implementing improvements to the testing methodology, resulting in optimized system reliability and responsiveness.",
					"Proactively addressed and resolved diverse issues within Consul, Terraform, AWS, and Kubernetes during on-call rotations, showcasing a robust troubleshooting skill set and ensuring continuous system reliability.",
					"Received 10 recognition awards for exceptional performance.",
					"Established comprehensive performance testing for edge computing solutions, including test case creation, pipeline automation, documentation, and execution of over 20 tests across multiple environments.",
					"Updated on-call onboarding guides and operational runbooks for improved clarity and usability.",
					"Created a collaborative communication platform to enhance team visibility and cohesion.",
					"Led the design and implementation of an innovative project in the last quarter, driving forward strategic initiatives.",
					"Contributed significantly to meeting tight compliance deadlines for SOC2 evidence collection.",
					"Managed complex deployments and releases for service discovery platforms, ensuring major upgrades with no service interruptions through thorough testing and stakeholder coordination.",
					"Joined the on-call rotation within one month of hire, resolving over five incidents, demonstrating rapid adaptation and problem-solving skills.",
					"Supported infrastructure buildouts and service expansions, aligning with team goals and business requirements.",
					"Transformed release scheduling and deployment timelines with automation, reducing processes from a full day to seconds.",
					"Crafted an automation pipeline for real-time documentation updates upon code revisions, facilitating a GitOps workflow.",
					"Pioneered the development of parallel deployment strategies, minimizing update rollout times across a network of over 80 data centers.",
					"Reorganized a version control system's structure to reduce operational overhead and address legacy code issues.",
					"Enhanced security in deployment pipelines by implementing the principle of least privilege.",
					"Set up health notifications for system reliability engineering teams, streamlining member account setups for monitoring services.",
					"Formulated an advanced process to replace load balancers in production environments without any service downtime.",
					"Introduced new integration plugins and optimized cloud resource management, resulting in substantial cost savings.",
				],
			},
		],
	},
	{
		id: 1,
		logo: telus,
		url: "https://www.telus.com",
		alt: "TELUS",
		date: "May 2020 - September 2023",
		roles: [
			{
				title:
					"Cloud DevOps Engineer - TV MiddleWare (Oct 2021 - September 2023)",
				summary:
					"Expert in developing, implementing, and maintaining secure cloud infrastructure for TELUS, including Kubernetes and cloud-based systems.",
				qualifications: [
					"Designed and implemented a comprehensive application performance and infrastructure monitoring solution across 20 GCP projects and 20+ Kubernetes clusters, improving system reliability and optimized resource utilization.",
					"Spearheaded the development of serverless applications using Python and Go for monitoring and cluster operations automation, resulting in improved efficiency and automated processes.",
					"Created a highly-available and geographically redundant Kubernetes infrastructure for multi-cluster service deployments, ensuring continuous service availability and disaster recovery.",
					"Automated agent installation across all infrastructure with Ansible playbooks and pipelines, saving significant time and improving operational efficiency.",
					"Strengthened cluster security by integrating infrastructure, container, and application vulnerability scanning agents into CI/CD pipelines for continuous protection.",
					"Managed Github organization administration and ensured secure access to Google Cloud services using Github Actions, JIRA, and a workload identity pool for improved workflow and collaboration.",
					"Established a cloud interconnect network to enable secure mTLS communication between on-premise services and cloud microservices, enhancing data security and privacy.",
					"Designed and maintained 60+ internal GCP and Kubernetes Terraform modules, improving infrastructure management efficiency and reducing manual error.",
					"Provided 24/7 on-call support for Kubernetes incident review, resulting in quick resolution of critical issues and minimal downtime for clients.",
				],
			},
			{
				title: "Security Consultant - Security DevOps (May 2020 - Oct 2021)",
				summary:
					"Responsible for identifying TELUS' business and security requirements and implementing world-class security systems based on strategic security roadmaps. Provided development and operational support for internal applications and services aimed at protecting and reinforcing security services for all corporate security managers.",
				qualifications: [
					"Designed and maintained Java and C# APIs, Windows & Linux OS services for identity and access management, improving security for over 80,000 employees.",
					"Developed end-to-end automation and migration of certificate creation, delivery, and installation for over 5000 managed certificates and 100 Active Directory groups with 5-fold efficiency improvement",
					"Reverse engineered over 20 Java web applications and over 80 cronjob operations for monolithic service breakdown enabling microservice refactoring",
					"Collaborated with development teams in Disaster Recovery projects migrating over 10 applications to a Google Cloud Platform platform.",
					"Successfully finished the Development and Implementation of Robotic Process Automation tasks as requested by Security Customer Care, earning two bravo awards for exceptional work.",
					"Prepared detailed design documents and spreadsheets that showcased the legacy web application and batch operations' connected systems, leading to the successful breakdown of monolithic services into microservices.",
					"Collaborated with the DevOps team on Disaster Recovery projects, successfully migrating 10+ applications to the Google Cloud Platform cloud environment.",
				],
			},
		],
	},
	{
		id: 2,
		logo: arvp,
		url: "https://arvp.org",
		alt: "Autonomous Robotic Vehicle Project",
		date: "Jan 2018 - Aug 2021",
		roles: [
			{
				title: "Administrative Lead (Sep 2019 - Sep 2020)",
				summary:
					"Served as a Logistics and Business Lead for a 60+ member team consisting of engineering students and graduates, tasked with designing and building an autonomous underwater vehicle for the annual RoboSub competition in San Diego. Achieved a ranking of 4th out of over 50 international teams in 2019 and 6th in 2020.",
				qualifications: [
					"Secured over $50,000 in funding for group operations and travel expenses through revenue generation",
					"Oversaw social media communications, managing a subscriber base of over 1000",
					"Redesigned legacy WordPress website (arvp.org) from the ground up, incorporating best practices for WordPress and security",
					"Served as the primary point of contact for University of Alberta Faculty of Engineering and RoboNation communications",
				],
			},
			{
				title: "Electrical/Software Robotics Designer (Jan 2018 - Aug 2021)",
				summary:
					"Contributed to the design and implementation of software and electrical systems, enabling autonomous operation of the robot vehicle.",
				qualifications: [
					"Implemented LQR methodology in redesign of robot's control system",
					"Designed and created reverse polarity protection for in-house circuit boards",
					"Developed TravisCI pipeline scripts to automate software builds and tests",
					"Contributed to the development of perception, control, and mapping modules",
					"Handled soldering and testing of custom-made printed circuit boards",
				],
			},
		],
	},
	{
		id: 3,
		logo: coe,
		url: "https://www.edmonton.ca",
		alt: "City of Edmonton",
		date: "Jan 2019 - May 2020",
		roles: [
			{
				title: "Open Source Developer",
				summary:
					"Participated in a mentorship program with the City of Edmonton, contributing to multiple open source full-stack and DevOps projects on GitHub.",
				qualifications: [
					"Contributed to open-source projects using Angular, Node.js, and Elasticsearch.",
					"Assisted in designing and developing a cloud infrastructure CI/CD pipeline with Docker and Jenkins.",
				],
			},
		],
	},
];
